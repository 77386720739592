import { Controller } from "@hotwired/stimulus";

function handleChange(element) {
  const input = element.querySelector(".js-organization-name");
  const radios = element.querySelectorAll(".js-organization-type");

  radios.forEach((radio) => {
    radio.addEventListener("change", (event) => {
      const name = setUsername(element);

      if (name && event.target.value == "personal") {
        input.value = name;
      }
    });
  });
}

function setUsername(element) {
  const firstName = element.querySelector(".js-user-first-name").value;
  const lastName = element.querySelector(".js-user-last-name").value;

  let name = firstName;

  if (firstName && lastName) {
    name += " ";
  }

  name += lastName;

  return name;
}

export default class extends Controller {
  connect() {
    handleChange(this.element);
  }
}
