import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "text" ]

  recalculate() {
    const count = this.element.querySelectorAll("input:checked").length;
    const textElement = this.findChildElementFrom(this.textTarget);

    textElement.textContent = textElement.textContent.replace(/\d+/, count);
  }

  findChildElementFrom(element) {
    if (element.childElementCount > 0) {
      return this.findChildElementFrom(element.children[0]);
    } else {
      return element;
    }
  }
}
