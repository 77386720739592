import { Controller } from "@hotwired/stimulus";
import * as WebAuthnJSON from "@github/webauthn-json";
import { getAuthenticityToken } from "../helpers/rails";

function handleError(error) {
  alert(error.message);
}

async function register(credential, name) {
  const response = await fetch("/two_factor/security_keys", {
    body: JSON.stringify({ name: name, credential: credential }),
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getAuthenticityToken(),
    },
    method: "POST",
  });

  if (response.status !== 200) {
    return alert(
      "There was an error registering your security key. Please try again or contact support."
    );
  }

  await response.json();

  window.location.href = "/two_factor";
}

export default class extends Controller {
  async create(event) {
    event.preventDefault();

    const form = event.target;

    const credentialOptions = JSON.parse(
      form.querySelector("input[name=options]").value
    );

    const name = form.querySelector("input[name=name]").value;

    if (name == null || name == "") {
      return alert("You must enter a name");
    }

    try {
      const credential = await WebAuthnJSON.create({
        publicKey: credentialOptions,
      });

      await register(credential, name);
    } catch (error) {
      handleError(error);
    }
  }
}
