import { Controller } from "@hotwired/stimulus";

const LABELS = Object.freeze({
  accept: "Allowing…",
  deny: "Denying…",
  reject: "Rejecting…",
});

export default class extends Controller {
  createHiddenInput(name) {
    const input = document.createElement("input");

    input.name = name;
    input.type = "hidden";
    input.value = "1";

    return input;
  }

  connect() {
    document.addEventListener("turbo:visit", this.handleVisit.bind(this));
    window.addEventListener("pageshow", this.handlePageShow.bind(this));
  }

  disconnect() {
    document.removeEventListener("turbo:visit", this.handleVisit.bind(this));
    window.removeEventListener("pageshow", this.handlePageShow.bind(this));
  }

  handlePageShow(event) {
    event.persisted && this.reenable();
  }

  handleVisit() {
    this.reenable();
  }

  reenable() {
    const buttons = Array.from(
      this.element.querySelectorAll(`button:disabled[type="submit"]`)
    );

    for (const button of buttons) {
      button.disabled = false;
    }
  }

  submit(event) {
    const form = event.target;
    const submitter = event.submitter;

    const buttons = form.querySelectorAll(`button[type="submit"]`);

    buttons.forEach((button) => {
      if (button === submitter) {
        const name = button.getAttribute("name");

        if (name) {
          const input = this.createHiddenInput(name);
          form.appendChild(input);
        }

        const { disableWith } = button.dataset;

        if (disableWith) {
          button.textContent = disableWith;
        } else if (LABELS[name]) {
          button.textContent = LABELS[name];
        }
      }

      button.disabled = true;
    });

    form.submit();
  }
}
