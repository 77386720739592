import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "query", "scope" ]

  connect() {
    this.currentFilter = this.element.querySelector("[name=filter]:checked")?.value || "all";
    this.filter();
  }

  filter() {
    this.scopeTargets.forEach((scope) => {
      if (
        !scope.textContent.toLowerCase().includes(this.queryTarget.value.toLowerCase())
        || (this.currentFilter == "selected" && !scope.querySelector("input").checked)
        || (this.currentFilter == "unselected" && scope.querySelector("input").checked)
      ) {
        scope.classList.add("hidden");
      } else {
        scope.classList.remove("hidden");
      }
    });
  }

  toggleFilter(ev) {
    this.currentFilter = ev.target.value;
    this.filter();
  }

}
