import { application } from "./application";

import Collapse from "./collapse";
import CreateOrganizationForm from "./create_organization_form";
import ExternalRedirectForm from "./external-redirect-form";
import ScopeFilter from "./scope_filter";
import SkipTwoFactorCheckbox from "./skip_two_factor_checkbox";
import Tally from "./tally";
import TurbolessForm from "./turboless_form";
import WebAuthnAuthenticationController from "./webauthn_authentication_controller";
import WebAuthnRegistrationController from "./webauthn_registration_controller";

import { ModalController } from "@krystal/shard";

application.register(
  "webauthn_authentication",
  WebAuthnAuthenticationController
);

application.register("collapse", Collapse);
application.register("create_organization_form", CreateOrganizationForm);
application.register("external_redirect_form", ExternalRedirectForm);
application.register("scope-filter", ScopeFilter);
application.register("skip_two_factor_checkbox", SkipTwoFactorCheckbox);
application.register("tally", Tally);
application.register("turboless_form", TurbolessForm);
application.register("webauthn_registration", WebAuthnRegistrationController);

// Register the various controllers for Shard components
application.register("modal", ModalController);
