import { Controller } from "@hotwired/stimulus";
import { getAuthenticityToken } from "../helpers/rails";

function updateState(state) {
  fetch("/skip_two_factor", {
    method: "POST",
    body: JSON.stringify({ skip_two_factor: state }),
    headers: {
      "X-CSRF-Token": getAuthenticityToken(),
      "Content-Type": "application/json",
    },
  });
}

export default class extends Controller {
  toggle(event) {
    const desiredState = event.target.checked;
    updateState(desiredState);
  }
}
