import { Controller } from "@hotwired/stimulus";
import * as WebAuthnJSON from "@github/webauthn-json";

import { getAuthenticityToken } from "../helpers/rails";

function handleError(error) {
  alert(error);
}

async function getOptions(url) {
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.json();
}

function handleCallback(url, returnTo) {
  return async function (credential) {
    const callbackResponse = await fetch(url, {
      body: JSON.stringify({ return_to: returnTo, credential: credential }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getAuthenticityToken(),
      },
      method: "POST",
    });

    if (callbackResponse.status != 200) {
      alert("server side error");
      return;
    }

    const response = await callbackResponse.json();

    window.location.href = response.return_to;
  };
}

export default class extends Controller {
  async authenticate(event) {
    event.preventDefault();

    const { dataset, href } = event.currentTarget;

    const options = await getOptions(href);

    WebAuthnJSON.get({ publicKey: options })
      .then(handleCallback(href, dataset.returnTo))
      .catch(handleError);
  }
}
