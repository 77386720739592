import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "body", "chevron" ]

  toggle() {
    this.bodyTarget.classList.toggle("hidden");

    if (this.bodyTarget.classList.contains("hidden")) {
      this.chevronTarget.classList.add("rotate-180");
    } else {
      this.chevronTarget.classList.remove("rotate-180");
    }
  }
}
