import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  connect() {
    document.documentElement.addEventListener("turbo:before-fetch-request", (ev) => {
      ev.detail.fetchOptions.redirect = "manual"
    })

    document.documentElement.addEventListener("turbo:submit-end", (ev) => {
      if (ev.detail.fetchResponse.response.type == "opaqueredirect") {
        window.location = this.element.querySelector("#return_to").value
      }
    })
  }
}
